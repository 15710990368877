import React, { useState } from "react";
import emailjs from "@emailjs/browser";
import "./App.css";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Typography,
  TextField,
  Button,
} from "@mui/material";

const initialState = {
  name: "",
  email: "",
  phone: "",
  message: "",
};

function App() {
  const [{ name, email, phone, message }, setState] = useState(initialState);
  const [openDialog, setOpenDialog] = useState(false);

  const handleCloseDialog = () => setOpenDialog(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        "service_9xteq7f",
        "template_yl9k69c",
        e.target,
        "RbyUVrn2XpayXESAh"
      )
      .then(
        (result) => {
          setOpenDialog(true);
        },
        (error) => {
          setOpenDialog(true);
        }
      );
      setTimeout(() => {
     return window.location.href = 'https://valiantmarketing.us'
    }
    , 3000);
  };

  return (
    <div className="App">
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ minHeight: "100vh" }}
        flexDirection="column"
      >
        <Typography
          variant="h4"
          className="glowing-text"
          style={{
            color: "#fff",
            textAlign: "center",
            textTransform: "uppercase",
            fontWeight: "bold",
          }}
          fontFamily="Roboto"
        >
          Valiant Digital Marketing
        </Typography>
          <br />
          <br />
          <Typography
            variant="h6"
            style={{
              color: "#fff",
              textAlign: "center",
              textTransform: "uppercase",
              fontWeight: "bold",
            }}
            // fontFamily="Roboto"
          >
          Sign Up for a Free Consultation
        </Typography>
        <br />
        <br />
        <form className="form" onSubmit={handleSubmit}>
          <TextField
            type="text"
            fullWidth
            placeholder="Name"
            name="name"
            value={name}
            onChange={(e) => setState({ ...name, name: e.target.value })}
          />
          <TextField
            fullWidth
            type="email"
            placeholder="Email Address"
            name="email"
            value={email}
            onChange={(e) => setState({ ...email, email: e.target.value })}
          />
          <TextField
            fullWidth
            type="tel"
            placeholder="Phone Number"
            name="phone"
            value={phone}
            onChange={(e) => setState({ ...phone, phone: e.target.value })}
          />
          <TextField
            multiline
            fullWidth
            rows="6"
            placeholder="What are your goals?"
            name="message"
            value={message}
            onChange={(e) => setState({ ...message, message: e.target.value })}
          />
          <Button variant="contained" type="submit" fullWidth>
            Submit
          </Button>
        </form>
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle style={{ textAlign: "center" }}>
          {openDialog ? <h4>Message Sent</h4> : <h4>Try Again</h4>}
        </DialogTitle>
        <DialogContent>
          <h4>
            {openDialog
              ? "Your message has been sent successfully! We will be in contact with you shortly."
              : "There was an error. Please try again."}
          </h4>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default App;